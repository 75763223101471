.teeup-container {
    position: absolute;
    left: 0;
    /* right: 0;
    bottom: 0; */
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bezel .teeup-header {
    padding: 17px 24px 22px 24px;
    font-family: GreycliffCF;
    font-size: 22px;
}

.teeup-container .field-input-container {
    padding: 15px 24px 19px 24px;
    border-bottom: 1px solid #f0f0f0;
    display: flex;
    flex-direction: row;
}

.teeup-container .invitees-container {
    padding: 11px 24px 19px 24px;
    flex-direction: column;
}

.invitees-container .invitees {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
}

.invitees {
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
}
.invitees::-webkit-scrollbar { 
    display: none;
}

.invitees .invitee {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 16px;
    margin-top: 11px;
}

.invitees .avatar {
    width: 48px;
    height: 48px;
    border-radius: 24px;
    margin-bottom: 4px;
}

.field-input-container .icon {
    margin-right: 12px;
}

.teeup-container .rounded-button {
    margin: 25px 0 25px 0;
    width: 90%;
    align-self: center;
}

.teeup-container .field-input {
    width: 100%;
    border: none;
    outline: none;
    height: 100%;
}

.teeup-container .header {
    background-color: #f0f0f0;
    padding: 22px 24px 8px 24px;
}

.teeup-container .footer {
    background-color: #f0f0f0;
    padding: 5px 24px 3px 24px;
}

.teeup-container .bezel {
    width: 392px;
    height: 698px;
    z-index: 1000;
    background-color: white;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.24);
}

.teeup-container .overlay {
    z-index: 1000;
    background-color: white;
    opacity: .6;
}

.teeup-toolbar{
    padding: 16px;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.24);
}

.react-tooltip-lite {
    background: #222222;
    color: white;
    margin-top: 15px;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.24);
    padding: 16px;
    border-radius: 8px;
}

.react-tooltip-lite-arrow {
    border-color: #222222;
    margin-top: 15px;
}

.toolbar{
    width: 404px;
}

.toolbar-header{
    font-family: greycliffcf-demibold;
    color: #ffffff;
    font-size: 22px;
}

.toolbar-content{
    font-family: greycliffcf-medium;
    color: #ffffff;
    font-size: 16px;
}

.tooltip-button{
    float: right;
    border-radius: 24.5px;
    background-color: #f42862
}
.tooltip-button-inactive{
    background-color: #9d9d9d;
    float: right;
    border-radius: 24.5px;
}

.tooltip-button-text{
    font-family: greycliffcf-demibold;
    font-size: 16px;
    padding: 5px 18px 8px 18px;
}

.tooltip-radio-input{
    font-family: greycliffcf-demibold;
    font-size: 16px;
    color: #ffffff;
}

.inactive-button{
    background-color: #e1e1e1;
    display: inline-block;
    border-radius: 1.5em;
    /* padding: 2.25em; */
    width: 172px;
    padding-top: 0.6875em;
    padding-bottom: 0.875em;
    cursor: pointer;

    display: flex;
    justify-content: center;
}

.rounded-button-text-grey{
    color: #9d9d9d;
    letter-spacing: -0.4px;
    text-align: center;

    font-family: greycliffcf-demibold;
    font-size: 1.1875rem;
}

#start-container {
    display: flex;
    flex-direction: row;
}

#start-info-text {
    margin-top: 1em;
    margin-bottom: 1em;
}

#start-phone-image {
    width: 20em;
}

#start-left-column {
    margin-right: 5em;
}

#start-tos {
    margin: 1em;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    /* margin-right: 1em;
    margin-left: 1em;
    margin-bottom: 1em */
}

#start-tos-text {
    overflow-wrap: overflow-wrap;
}
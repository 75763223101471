#redirect-container{
    margin-left: 25%;
    margin-right: 25%;
}
.activity-example-container {
    margin-right: 24%;
    margin-left: 24%;
    border: 1px #f42862 solid;
    border-radius: 20px;
    padding: 0px 16px 20px 16px;
}
.txt_top{
    font-family: Helvetica;
    font-size: 25px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.84;
    letter-spacing: -0.4px;
    color: #f67ca1;
}
.secoundary-text{
    font-family: Helvetica;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.31;
    letter-spacing: -0.4px;
    color: #253d66;
}
.txt_bottom{
    font-family: Helvetica;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.31;
    letter-spacing: -0.4px;
}
.example-paragraph {
    font-family: Helvetica;
    font-size: 16px;
    line-height: 1.31;
    letter-spacing: -0.4px;
    color: #161616;
    margin-top: 20px;
    display: inline-block;
}
.example-paragraph-purple {
    color: #f42862;
    font-weight: bold;
}
.example-paragraph-orange{

}
.example-paragraph-bold {
    font-weight: bold;
}
.example-paragraph-underline {
    text-decoration: underline;
}

.good-examples-container{
    padding-top: 30px;
}
.good-examples-header-container{
    display: flex;
    justify-content: space-between;
    padding-right: 12px;
}
.good-examples-header{
    font-family: Helvetica;
    font-size: 16px;
    letter-spacing: -0.4px;
    color: #161616;
}
.good-examples-content-container{
    display: flex;
    justify-content: space-between;
    padding-right: 8px;
    padding-left: 8px;
    align-items: center;
    margin-bottom: 1px;
}
.good-examples-paragraph{
    font-family: Helvetica;
    font-size: 16px;
    line-height: 1.31;
    letter-spacing: -0.4px;
    color: #161616;
}
.color-orange{
    color: #ff7d3b;
}
.color-pink-2{
    color: #f67ca1;
}
.color-teal{
    color: #7071e9;
}
.color-blue{
    color: #5227a6;
}
.example-button{
    width: 44px;
    height: 20px;
    border-radius: 8px;
    background-color: #ffffff;
    border:#e1e1e1 1px solid;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right:8px;
}
.example-button-text{
    font-family: Helvetica;
    font-size: 8px;
    letter-spacing: -0.5px;
    color: #080908;
}
.example-button-arrows{
    content:url("../../assets/img/arrows/arrows.png");
    height: 8px;
    width: 4px;
}
.activity-table{
    margin-left: auto;
    margin-right: auto;
    max-width: 900px;
}
.activity-table th{
    font-family: greycliffcf-demibold;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: -0.4px;
    text-align: center;
    color: #7b7b7b;
}

.activity-table-example{
    border:1px solid #f42862;
    padding: 10px;
    border-radius: 16px !important;
}
.activity-table-first-element{
    padding-left: 7px;
    padding-right: 7px;
}
.activity-table-padding{
    padding-right: 6px;
}
.activity-table td{
    padding-top: 12px;
    padding-bottom: 7px;
}
.action-input{
    width: 108px;
    margin-right: 16px;
}
.action-input-status{
    width: 118px;
    margin-right: 16px;

}
.activity-input{
    width: 236px;
}
.with-input{
    width: 159px;
}
.location-input{
    width: 236px;
}
.addAnotherActivity{
    width: 200px;
    margin: 0 auto;
}
.add-activity-button{
    text-align: center;
    color: #fe2867;
    font-family: greycliffcf-demibold;
    font-size: 16px;
    line-height: 1.31;
    letter-spacing: -0.4px;
    user-select: none;
    margin-top: 20px;
    cursor: pointer;
}
.add-activity-button:active{
    opacity: 0.5;
}
.activity-button-container{
    display: flex;
    flex-direction: row;
    margin-left: 24%;
    margin-right: 24%;
    justify-content: space-between;
    margin-bottom: 277px;
}
.quiz-input{
    width: 206px;
    margin-right: 39px;
}
.checkbox-container{
    margin: 0 auto;
}

.activity-table .quiz-asking-question{
    font-family: Helvetica;
    font-size: 16px;
    line-height: 1.31;
    letter-spacing: -0.4px;
    color: #161616;
    font-weight: normal;
    text-align: left;
    padding-right: 16px;
}
.quiz-question-row{
    width: 255px;
    font-family: Helvetica;
    font-size: 16px;
    line-height: 1.31;
    letter-spacing: -0.5px;
    color: #080908;
}
.selector-radio-buttons{
    margin-right: 24%;
    margin-left: 24%;
}
.selector-text-container{
    display: flex;
    flex-direction: column;
    padding-right: 80px;
}
.trash {
    width: 16px;
    height: 16px;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -0.4px;
    color: #000000;
}
.trash::after{
    content: '\f2ed';
    font-family: font-awesome-regular;
    color: #000000;
}
.page-indicator-container {
    margin: 0 18.1%;
    padding: 0 3.2%;
    /*padding:0 6.4%; margin:0 24.3%;*/
}

.step-inside {
    font-family: greycliffcf-bold;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    height: 16px;
    line-height: 15px;
}

.check {
    width: 16px;
    height: 16px;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -0.4px;
    color: #ffffff;
}
.check::after{
    content: '\f00c';
    font-family: font-awesome-regular;
    color: #ffffff;
}

.clearfix:after,
.clearfix:before{
    content: " ";
    display: table
}
.bootstrapWizard {
    list-style: none;
    padding: 0;
    position: relative;
    width: 100%;
    display: inline-flex;
}

.bootstrapWizard a:active,
.bootstrapWizard a:focus,
.bootstrapWizard a:hover {
    text-decoration: none
}

.bootstrapWizard li {
    display: block;
    float: left;
    width: 25%;
    text-align: center;
    padding-left: 0
}

.bootstrapWizard li:before {
    border-top: 3px solid #55606E;
    content: "";
    display: block;
    font-size: 0;
    overflow: hidden;
    position: relative;
    top: 11px;
    right: 1px;
    width: 100%;
    z-index: 1
}

.bootstrapWizard li:first-child:before {
    left: 50%;
    max-width: 50%
}

.bootstrapWizard li:last-child:before {
    max-width: 50%;
    width: 50%
}

.bootstrapWizard li.complete .step {
    background: #0aa66e;
    padding: 1px 6px;
    border: 3px solid #55606E
}

.bootstrapWizard li .step i {
    font-size: 10px;
    font-weight: 400;
    position: relative;
    top: -1.5px
}

.bootstrapWizard li .step {
    background: #B2B5B9;
    color: #fff;
    display: inline;
    font-size: 15px;
    font-weight: 700;
    padding: 7px 13px;
    border: 3px solid transparent;
    border-radius: 50%;
    line-height: normal;
    position: relative;
    text-align: center;
    z-index: 2;
    transition: all .1s linear 0s
}

.bootstrapWizard li.active .step,
.bootstrapWizard li.active.complete .step {
    background: deeppink;
    color: #fff;
    font-weight: 700;
    padding: 7px 13px;
    font-size: 15px;
    border-radius: 50%;
    border: 0px solid #55606E
}

.bootstrapWizard li.active .title,
.bootstrapWizard li.complete .title {
    color: #2B3D53
}

.bootstrapWizard li .title {
    color: #bfbfbf;
    display: block;
    font-size: 13px;
    line-height: 15px;
    max-width: 100%;
    position: relative;
    table-layout: fixed;
    text-align: center;
    top: 20px;
    word-wrap: break-word;
    z-index: 104
}


/*page-indicator styles*/

.bootstrapWizard li:before {
    border-top: 2px solid #e1e1e1;
    border-radius: 1px;
    content: "";
    display: block;
    font-size: 0;
    overflow: hidden;
    position: relative;
    top: 15px;
    z-index: 1;
    width: 50%;
    left: -25%;
}

.bootstrapWizard li:first-child:before {
    left: 0;
    max-width: 0;
}

.bootstrapWizard li .step {
    display: inline-flex;
    height: 32px;
    width: 32px;
    justify-content: center;
    align-items: center;
}

.page-indicator-container {
    margin: 0 18.1%;
    padding: 0 3.2%;
    /*padding:0 6.4%; margin:0 24.3%;*/
    padding-bottom: 47px;
}

.check {
    width: 16px;
    height: 16px;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -0.4px;
    color: #ffffff;
}

.bootstrapWizard li .step i {
    font-size: 16px;
    font-weight: 400;
    position: relative;
    top: 0;
}

.bootstrapWizard li .step {
    border: 0;
    padding: 0;
}

.bootstrapWizard li.active .step, .bootstrapWizard li.active.complete .step {
    border: 0;
    background-color: #f42862;
    padding: 0;
}

.step-inside {
    font-family: greycliffcf-bold;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    height: 16px;
    line-height: 15px;
}

.active-step{
    background-color: #f42862!important;
}
.title{
    font-family: greycliffcf-demibold;
    font-size: 16px!important;
    letter-spacing: -0.4px;
    color: #9d9d9d!important;
}

.title-complete{
    color: #222222!important;
}
.label-for-input {
  align-self: flex-start;
  font-family: greycliffcf-demibold;
  font-size: 16px;
  line-height: 1.31;
  letter-spacing: -0.4px;
  color: #767676;
  width: 300px;
  padding-right: 20px;
  padding-top: 12px;
}

.label-for-input-radio {
  padding-top: 0 !important;
}

.last-input-form {
  margin-bottom: 34px;
  width: 428px;
  padding: 12px 16px 15px 16px;
  font-family: greycliffcf-demibold;
  font-size: 16px;
  line-height: 1.31;
  letter-spacing: -0.4px;
  color: #222222;
  background-color: #ffffff;
  border: none;
  border: 1px solid #e1e1e1;
  border-radius: 8px !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.input-form-text {
  width: 394px !important;
}
.tee-up-sent {
  margin-bottom: 14px;
  font-family: Helvetica;
  font-size: 32px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.5px;
  color: #101010;
  text-align: center;

}
.youve-just-earned {

  font-family: Helvetica;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.4px;
  color: #7b7b7b;
  text-align: center;

}
.to-earn-another-20 {
  width: 531px;
  height: 57px;
  font-family: Helvetica;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.4px;
  text-align: center;
  color: #000000;
  text-align: center;

}
.to-earn-another-20 .text-style-1 {
  font-weight: bold;
}
.enter-your-email-acc {
  text-align: center;
  margin-bottom: 24px;
  margin-top: 4px;
  font-family: Helvetica;
  font-size: 19px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.4px;
  color: #7b7b7b;
}
.rectangle {
  width: 172px;
  height: 48px;
  border-radius: 24.5px;
  margin-top: 52px;
  cursor: pointer;
  margin-left: 40px;
  margin-right: 40px;

}
.buttonContainer{
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.next, .newTeeup {
  width: 39px;
  height: 23px;
  margin: 12px auto;
  font-family: greycliffcf-demibold;
  font-size: 19px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.4px;
  text-align: center;
  color: white;
}
.newTeeup{
  width: 100%;
}
.popUp-container{
  width: 496px;
  height: 296px;
  margin: 150px auto;
  border-radius: 8px;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.24);
  background-color: white;
}
.popup-button{
  width: 120px;
  height: 48px;
  border-radius: 24.5px;
  background-color: #f42862;
  margin: 0 auto;
  cursor: pointer;
}
.popUp-txt{
  text-align: center;
  padding-top: 100px;
  padding-bottom: 50px;
}
.popup-button .btn_text {
  font-family: greycliffcf-demibold;
  margin-top: 30px;
  font-size: 19px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.4px;
  text-align: center;
  padding-top: 10px;
  color: white;
}
.disabled{
  background-color: #e1e1e1;
}

.active{
  background-color: #f42862;
}
.active:hover{
  background: #12275F;
}
.box_item_component .item {
    border-top: none;
    width: 14rem;
    overflow: hidden;
    cursor: move;
    font-family: greycliffcf-demibold;
    font-size: .9375rem;
}

.box_item_component .bucket_item {
    width: 12rem;
    padding: 0 1rem 0 0 !important;
    border-radius: 1rem !important;
}

.bucket_item .close {
    cursor: pointer;
}
 
.box_item_component .tagged {
    border: 4px solid red !important;
}

.box_item_component .highlighted {
    border-top: 40px solid #ddd;
}

.box_item_component .item {
    /* position: absolute; */
    border-radius: 0.5rem;
    padding: 0.5rem;
    /* margin-left: 0.75rem;
    margin-right: 0.75rem;
    margin-top: 0.5rem; */
    background-color: #ffffff;
    border: 1px solid #e1e1e1;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.box_item_component .item-selected {
    /* background-color: aqua; */
    /* opacity: .6; */
    background-color: #eeeeee;
}


.box_item_component .group {
    background-color: white;
    border-radius: 1.25rem;
    width: 9rem;
    border-width: 1px;
}

.box_item_component .group-selected {
    background-color: #00bcf6;
}

.contextMenu--list {
    position: fixed;
    background: white;
    box-shadow: 0px 2px 10px #999999;
    padding: 10px;
}

.contextMenu--option {
    cursor: pointer;
}

.box_item_component .group .content {
    flex: 1;
    border-bottom: 1px solid #222222;
    text-align: center;
}

.box_item_component input {
    width: 6rem;

    padding: 0;
    border-radius: 0;
    background-color: transparent;
    color: #222222;
    border: none;
    outline: none;
    text-align: center;
}

.box_item_component input .highlighted {
    background-color: yellow;
}

.draggable_absolute {
    position: absolute !important;
}

.box_item_component .image {
    width: 2rem;
    height: 2rem;
    background-color: #f0f0f0;
    border-radius: 100rem;
}

.activity-tag {
    margin-right: .25rem;
}

.group-tag-icon{
    align-self: center;
    height: .75rem;
    width: 1rem;
    margin-right: .25rem;
}

.box_item_component .content {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
}

.box_item_component .tags .tag {
    margin-right: 0.25rem;
}

.box_item_component .tags {
    margin-top: 0.25rem;
}

.box_item_component .name {
    color: #222222;
}

.box_item_component .spacer {
    flex: 1;
}

.box_item_component .grabber {

}

.flex-container{
    flex-direction: row;
    flex: 1;
    display: flex;
}

.silent-placeholder{
    opacity: 1;
    width: 20px;
    height:100px
}
.basics-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.section-fieldset {
    border: none;
    padding-top: 47px;
    padding-bottom: 32px;
}

.section-header {
    font-family: greycliffcf-demibold;
    font-size: 22px;
    letter-spacing: -0.5px;
    color: #222222;
    border: none;
}

.input-container {
    flex-direction: row;
    display: flex;
    margin-bottom: 12px;
    align-items: flex-start;
}

.label-for-input {
    align-self: flex-start;
    font-family: greycliffcf-demibold;
    font-size: 16px;
    line-height: 1.31;
    letter-spacing: -0.4px;
    color: #767676;
    width: 300px;
    padding-right: 20px;
    padding-top: 12px;
}

.label-for-input-radio {
    padding-top: 0 !important;
}

.multi-select {
    width: 428px;
}

.input-form {
    width: 428px;
    padding: 12px 16px 15px 16px;
    font-family: greycliffcf-demibold;
    font-size: 16px;
    line-height: 1.31;
    letter-spacing: -0.4px;
    color: #222222;
    background-color: #ffffff;
    border: none;
    border: 1px solid #e1e1e1;
    border-radius: 8px !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
.input-form-invalid{
    border: 1px solid red;
}

.options-placeholder {
    color: #9d9d9d;
}

.select-form {
    background: #fff url(../../assets/img/arrows/arrows.png) no-repeat;
    background-size: 8px 18px;
    background-position: right 10px center;
    -webkit-appearance: none;
    overflow-y: scroll;
}

.input-form-text {
    width: 394px !important;
}

.input-option {
    padding: 12px 16px 15px 16px;
}

.input-form::placeholder {
    color: #9d9d9d;
}

.left-text-container {
    flex-direction: column;
    display: flex;
}

.label-for-information {
    color: #9d9d9d;
}

.input-form-radio {
    width: 56.46%;

}

.radio-padding {
    padding-top: 23px
}

.radio-input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    border-radius: 50%;
    width: 16px;
    height: 16px;
    border: 1px solid #e1e1e1;
    /*transition: 0.2s all linear;*/
    outline: none;
    position: relative;
    top: 2px;
    margin-right: 2px;
}

input:checked {
    padding: 2px;
    background-color: #f42862;
    border: 1px solid #9d9d9d;
    background-clip: content-box;
}

input[type=checkbox], input[type=radio] {
    margin: 0;
}

.input-radio-label {
    padding-right: 24px;
    font-family: greycliffcf-demibold;
    color: #767676;
    font-size: 16px;
    line-height: 1.31;
    letter-spacing: -0.4px;
}

input:checked + .input-radio-label {
    font-weight: bold;
}

input[type=radio]:checked ~ label {
    color: #222222 !important;
}

/* width */
.custom-scroll::-webkit-scrollbar {
    width: 20px;
    border-radius: 4px;
}

/* Track */
.custom-scroll::-webkit-scrollbar-track {
    background: white;
    margin-top: 3px;
    margin-bottom: 3px;
    width: 8px;
}

/* Handle */
.custom-scroll::-webkit-scrollbar-thumb {
    background: #767676;
    border-radius: 4px;
    width: 8px;
    box-shadow: inset -12px 0px white;
}

.buttons-container{
    margin-left: auto;
    padding-bottom: 60px;
}
.selector-text-top{
    font-family: greycliffcf-demibold;
    color: #222222;
    size: 16px;
}
.selector-text-bottom{
    padding-top: 8px;
    font-family: greycliffcf-medium;
    size: 14px;
    color: #767676;
}

.orange{
    border: 1px solid #ff7d3b;
}
.red{
    border: 1px solid #f42862
}
.blue{
    border: 1px solid#7071e9
}
.purple{
    border: 1px solid #5227a6
}

.autocomplete-dropdown-container{
    position: absolute;
    z-index: 1;
    width: 394px;
}
.suggestion-item{
    border: 1px solid #767676;
    padding: 10px;
}
.exampleInterest{
   float: left;
   padding: 10px; 
   border: solid 1px #363fa2; 
   border-radius: 20px;
   text-align: center;
   cursor: pointer
  }
.exampleInterest:hover{
  background: #363fa2;
  color: white
}
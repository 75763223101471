#login-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login-input {
    width: 360px !important;
    margin-bottom: 16px;
}

.rounded-button-email-width {
    width: 172px !important;
    margin-bottom: 32px;
}

.rounded-button-width {
    width: 330px !important;
    margin-bottom: 32px;
}

.rounded-button {
    /* border: 1px black solid; */
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
    justify-content: space-around;
}
.login-next{color: white}
.disabled{ background-color: #e1e1e1;}
.active{ background-color: #f42862; }
.active:hover{ background: #12275F; }
#tos-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#tos-inner-container {
    display: flex;
    flex-direction: column;
    width: 48.75em;
}

#tos-button-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;   
}
#verify-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.info_input{
    background: none;
     border: none;
     outline: none;
     text-align: right;
     width: 200px;
}
.filedEnabled{
    height: 2px;
    width: 100%;
    transition: all 0.3s linear;
}
.filedDisabled{
    width: 0;
}

.filed{
    position: relative;
    float: left;
    background: #ca4040;
}
.editContainer{
    height: 45px;
    float: left; 
    margin-right: 10px; 
    margin-top: 10px;
} 
.edit_info_icons{
    width: 10px;
    cursor: pointer;
    margin-left: 10px
}
.edit-tel-input{
    width: 361px !important
}
.font-demibold-header {
    font-size: 1.375em;
    font-family: greycliffcf-demibold;
    color: #101010;
}

.font-demibold-big {
    font-size: 1em;
    font-family: greycliffcf-demibold;
    color: #101010;
}

.font-regular-small {
    font-size: .8125em;
    font-family: greycliffcf-regular;
    color: #101010;
}

.font-demibold-medium {
    font-size: .75em;
    font-family: greycliffcf-demibold;
    color: #101010;
}

.font-regular-header {
    font-size: 1.375em;
    font-family: greycliffcf-regular;
    color: #101010;
}

.font-regular-prebig {
    font-size: .9375em;
    font-family: greycliffcf-regular;
    color: #101010;
}

.font-medium-prebig {
    font-size: .9375em;
    font-family: greycliffcf-demibold;
    color: #101010;
}

.font-regular-postbig {
    font-size: 1.0625em;
    font-family: greycliffcf-regular;
    color: #101010;
}

.font-demibold-postbig {
    font-size: 1.0625em;
    font-family: greycliffcf-demibold;
    color: #767676;
}

.font-regular-big {
    font-size: 1em;
    font-family: greycliffcf-regular;
    color: #101010;
}

.font-regular-medium {
    font-size: .75em;
    font-family: greycliffcf-regular;
    color: #101010;
}

.font-bold-header {
    font-size: 1.375em;
    font-family: greycliffcf-bold;
    color: #101010;
}

.font-bold-big {
    font-size: 1em;
    font-family: greycliffcf-bold;
    color: #101010;
}

.font-bold-medium {
    font-size: .75em;
    font-family: greycliffcf-bold;
    color: #101010;
}

.color-black {
    color: #101010;
}

.color-gray {
    color: #767676;
}
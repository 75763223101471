
#redirect-container {
    display: flex;
    align-items: center;
    flex-direction: column;
}

#redirect-title-text {
    margin-top: 1em;
    margin-bottom: 1em;

    font-family: Helvetica;
    font-size: 22px;
}

#redirect-info-text {
    font-family: Helvetica;
    font-size: 16px;
    color: #797979;
    text-align: center;
}
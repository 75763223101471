@import '~antd/dist/antd.css';

.app-container{
  min-height: 1404px;
  background-color: white;
}
.header-container{
  padding: 20px 0 2.4% 11.1%;
}
.header-image{
  color: rgb(244 40 98);
  height: 32px;
}
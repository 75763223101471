#contact-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    /* display: 'flex', justifyContent: 'center' */
}
.test{
    background: black;
    border-radius: 8px;
  border: solid 1px #e1e1e1;
}
.flag-dropdown {
    position: absolute;
    top: 0;
    bottom: 0;
    padding: 0;
    background-color: black !important;
    border: 1px solid #cacaca;
    border-radius: 3px 0 0 3px;
  }
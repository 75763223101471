.popUp-background{
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  background-color: rgba(225, 225, 225,0.8);
}
.video-pop-up {
  width: 840px;
  height: 658px;
  border-radius: 8px;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.24);
  background-color: #fff;
  margin: 0 auto;
  position: absolute;
  top: calc(50% - 324px); left: 0; bottom: 0; right: 0;
}

.video-txt {
  width: 792px;
  height: 42px;
  font-family: GreycliffCF-DemiBold;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.31;
  letter-spacing: -0.4px;
  color:#767676;
  clear: both;

}
.video {
  clear: both;
  width: 792px;
  height: 445px;
  background-color: #7e7979;
}
.video-header{
  margin-top: 17px;
  width: 158px;
  height: 26px;
  font-size: 22px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.5px;
  color: black;
}
.video-button{
  margin-top: 24px;
  width: 120px;
  height: 48px;
  border-radius: 24.5px;
  background-color: #f42862;
  float: right;
  cursor: pointer;

}
.video-button:hover{
  background: #12275F;
}
.video-btn-txt{
  font-size: 19px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.43px;
  color: white;
  text-align: center;
  padding-top: 12px;
}
.container{
  width: 792px;
  margin: 0 auto;
}
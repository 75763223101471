* {
    margin: 0;
    padding: 0;
  }
  
.main-container {
    margin-left: 10em;
    margin-right: 10em;
    margin-top: 1.5em;
    /* margin-bottom: 1.5em; */
}

.header-logo {
    margin-bottom: 1.5em;
    height: 30px;
}

.input-field {
    border-radius: .5em;
    border: 1px solid #e1e1e1;
    width: 22.5em;
    padding: 1em;
    padding-top: .9375em;
    padding-bottom: .75em;
}

.width-small {
    width: 10.75em;
    display: inline-block;
}

.text-left {
    text-align: start;
}

.text-right {
    text-align: end;
}

.margin-right125 {
    margin-right: 1.25em;
}

.margin-right05 {
    margin-right: .5em;
}

.margin-vertical15 {
    margin-top: 1.5em;
    margin-bottom: 1.5em;
}

.margin-bottom25 {
    margin-bottom: 2.5em;
}

.margin-bottom1 {
    margin-bottom: 1em;
}

.rounded-button {
    display: inline-block;
    border-radius: 1.5em;
    /* padding: 2.25em; */
    width: 172px;
    padding-top: 0.6875em;
    padding-bottom: 0.875em;
    cursor: pointer;

    display: flex;
    justify-content: center;
}

.bcolor-pink {
    background-color: #f42862;
}

.bcolor-disabled {
    background-color: #e1e1e1;
}

.bcolor-disabled:hover {
    background-color: #e1e1e1 !important;
}

.bcolor-pink:hover { 
    background-color: #112860;
  }

span {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.rounded-button-text {
  letter-spacing: -0.4px;
  text-align: center;
  color: #ffffff;

  font-family: greycliffcf-demibold;
  font-size: 1.1875rem;
}

/* checkmark */
.checkbox-container {
    display: block;
    position: relative;
    width: 1em;
    height: 1em;
    /* padding-left: 35px; */
    /* margin-bottom: 12px; */
    cursor: pointer;
    font-size: 1.25em;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default checkbox */
  .checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 1em;
    width: 1em;
    background-color: #eee;
  }
  
  /* On mouse-over, add a grey background color */
  .checkbox-container:hover input ~ .checkmark {
    background-color: #ccc;
  }
  
  /* When the checkbox is checked, add a blue background */
  .checkbox-container input:checked ~ .checkmark {
    background-color: #2196F3;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .checkbox-container input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .checkbox-container .checkmark:after {
    left: .25em;
    top: .1em;
    width: .3em;
    height: .5em;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: greycliffcf-bold;
  src: url("./assets/fonts/greycliffcf/greycliffcf-bold-salt.ttf") format("truetype");
}
@font-face {
  font-family: greycliffcf-demibold;
  src: url("./assets/fonts/greycliffcf/greycliffcf-demibold-salt.ttf") format("truetype");
}
@font-face {
  font-family: greycliffcf-medium;
  src: url("./assets/fonts/greycliffcf/greycliffcf-medium-salt.ttf") format("truetype");
}
@font-face {
  font-family: greycliffcf-regular;
  src: url("./assets/fonts/greycliffcf/greycliffcf-regular-salt.ttf") format("truetype");
}
@font-face{
  font-family: font-awesome-regular;
  src: url("./assets/fonts/font-awesome-pro/FontAwesome5Pro-Regular-400.otf") format("opentype");
}
@font-face{
  font-family: font-awesome-solid;
  src: url("./assets/fonts/font-awesome-pro/FontAwesome5Pro-Solid-900.otf") format("opentype");
}
@font-face{
  font-family: font-awesome-light;
  src: url("./assets/fonts/font-awesome-pro/FontAwesome5Pro-Light-300.otf") format("opentype");
}

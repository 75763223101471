.times-icon{
    font-family: font-awesome-regular;
    font-size: 24px;
}
.times-icon::after {
    color: #f42862;
    content: '\f00d';
}
.modal-flex-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.headerText{
    font-family: greycliffcf-demibold;
    font-size: 22px;
    color: #222222;
}

.modal-input-large{
    width: 416px!important;
}

.modal-input{
   width: 174px!important;
}

.modal-or{
    margin-left: 9px;
    margin-right: 9px;
    font-family: greycliffcf-demibold;
    font-size: 16px;
}

.modal-input-margins{
    margin-top: 21px;
    margin-bottom: 18px;
}

.no-padding{
    padding: 0!important;
}

.modal-asterisk-text{
    font-family: greycliffcf-demibold;
    font-size: 12px;
    color: #a3a3a3;
}

.ant-modal-mask {
    background-color: rgba(255, 255, 255, 0.8);
}

.ant-modal {
    width: 392px !important;
}

.ant-modal-content {
    border-radius: 8px;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.24);
}

.ant-modal-header {
    border-radius: 8px 8px 0 0;
    padding-bottom: 22px;
    border: none;
}

.ant-modal-body {
    padding: 0;
}

.ant-modal-footer {
    border-radius: 0 0 8px 8px;
    padding: 24px;
    border: none;
}

.ant-modal-footer .rounded-button {
    padding: 10px;
    border-radius: 24px;
}

.ant-modal-title-text {
    font-family: 'greycliffcf-demibold';
    font-size: 22px;
    letter-spacing: -0.5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ant-modal-close-button {
    width: 16px;
    height: 16px;
    cursor: pointer;
}

.ant-modal-content .label-wrapper {
    background-color: #f0f0f0;
    padding: 0 24px;
    color: #767676;
}

.ant-modal-content .label-wrapper .primary-text {
    font-family: 'greycliffcf-demibold';
    font-size: 15px;
    letter-spacing: -0.3px;
    padding-top: 20px;
    padding-bottom: 6px;
}

.ant-modal-content .label-wrapper .secondary-text {
    font-family: 'greycliffcf-regular';
    font-size: 13px;
    line-height: 1.38;
    letter-spacing: -0.2px;
    padding: 5px 0;
}

.ant-modal-content .input-wrapper {
    height: 56px;
    padding: 0 24px;
    border-bottom: 1px solid #f0f0f0;
    display: flex;
    align-items: center;
}

.ant-modal-content .input-wrapper img {
    margin-right: 12px;
}

.ant-modal-content .input-wrapper input {
    padding: 0;
    padding-bottom: 4px;
    flex: 1;
    height: 100%;
    border: none;
    font-family: 'greycliffcf-regular';
    font-size: 17px;
    line-height: 1.29;
    letter-spacing: -0.4px;
    color: #222222;
    box-shadow: none !important;
}

.ant-modal-content .input-wrapper input::-webkit-input-placeholder {
    color: #9d9d9d;
}

.contacts-container {
    padding: 8px 24px;
    color: #222222;
}

.contacts-container .contacts-num-label {
    font-family: 'greycliffcf-demibold';
    font-size: 15px;
    line-height: 1.47;
    letter-spacing: -0.3px;
}

.contacts-container .contacts-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -8px;
}

.contacts-listitem {
    padding: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contacts-listitem-avatar {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    overflow: hidden;
    background-color: #f0f0f0;
    margin-bottom: 4px;
}

.contacts-listitem-avatar img {
    display: block;
    width: 100%;
    height: 100%;
}

.contacts-listitem-name {
    font-family: 'greycliffcf-regular';
    font-size: 12px;
    line-height: 1.33;
    letter-spacing: -0.2px;
    text-align: center;
}

.box-activity-item-modal {
    border: solid 1px #e1e1e1;;
    background-color: white;
    flex-direction: row;
    display: flex;
    border-radius: 5px;
    margin-bottom: 5px;
    width: 241px;
    height: 40px;
    display: flex;
    align-items: center;
    padding-left: 10px;

  }

  .contact-tel-input{
      width: 174px !important
  }

.box-activity-item_add {
    width: 241px;
    height: 40px;
    border-radius: 9px;
    border: solid 1px #e1e1e1;
    background-color: #f8f8f8;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
.add_header{
    font-family: GreycliffCF-DemiBold;
    font-size: 15px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -0.3px;
    color: #9d9d9d;
    margin-left: 6px;
    margin-top: 6px;
}

.box-activity-name{
    font-family: GreycliffCF-DemiBold;
    flex: 14;
    font-size: 15px;
    font-weight: 600;
    font-style: normal;
    margin-left: 6px;
    margin-top: 6px;
    letter-spacing: -0.3px;
    color: #1c1c1c;

}

.box-activity-header{
    width: 73px;
    height: 20px;
    font-family: GreycliffCF-DemiBold;
    font-size: 17px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -0.4px;
}

.react-draggable, .cursor {
    cursor: move;
}

.no-cursor {
    cursor: auto;
}

.cursor-y {
    cursor: ns-resize;
}

.cursor-x {
    cursor: ew-resize;
}

.create-group-button {
    position: absolute;
}

.react-draggable strong {
    background: #ddd;
    border: 1px solid #999;
    border-radius: 3px;
    display: block;
    margin-bottom: 10px;
    padding: 3px 5px;
    text-align: center;
}

.boxes {
    flex: 1;
    display: flex;
    height: 668px;
    transition: all 0.1s linear;
    /* width: 2000px; */
}

.social-desktop-container {
    margin-left: 10%;
    margin-right: 10%;
}

.box_desktop .context-menu {
    position: fixed;
    visibility: hidden;
}

.context-menu .overlay {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.social-desktop-hint {
    margin-right: .75rem;
    margin-left: .75rem;
    margin-bottom: 2.5rem;
    border: 1px #f42862 solid;
    border-radius: 1.25rem;
    padding: .875rem 1.5rem 1rem 1.5rem;

    align-content: center;
    justify-content: center;
    align-items: center;
}

.social-desktop-hint .tag {
    top: .125em;
    position: relative;
}

.current-activity {
    margin-bottom: 2.5rem;
    border: 1px #e1e1e1 solid;
    border-radius: .75rem;
    padding: .25rem;
}

.tab_header {
    margin: 10px;
}

.boxes .tabs {
    width: 4rem;
    margin-top: 2.75rem;
    margin-left: 0.75rem;
    display: flex;
    flex-direction: column;
}

.boxes .tabs .tab {
    margin-top: 0.75rem;
    height: 2.25rem;
    padding: .75rem;
    border-radius: 1.5rem 0 0 1.5rem;
    cursor: pointer;
    align-items: center;
    justify-content: center;
}

.boxes .tabs .selected {
    background-color: #f0f0f0;
}

.boxes .box_container_tags {
    display: flex;
    flex-direction: column;
    flex: 0;
}

.box_title {
    margin: 0 0 6px 16px;
}

.searchbar {
    margin: 16px 16px 0 16px;
}

.searchbar .magnifier {
    width: 16px;
    height: 16px;
    margin-bottom: 5px;
    margin-right: 5px;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -0.4px;
    color: #9d9d9d;
}

.searchbar .magnifier::after {
    content: '\f002';
    font-family: font-awesome-regular;
    color: #9d9d9d;
}

.searchbar .text {
    flex: 1;
    text-align: left;
}

.boxes .box {
    border-radius: 1.25rem;
    margin: 0 10px 0 0;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 17rem;
    background-color: #f0f0f0;
}

.box .droptarget {
    display: flex;
    flex: 1;
    padding: .5rem;
}

.box_desktop .droptarget {
    flex: 1;
    padding: 1rem;
}

.boxes .scroller {
    flex: 1;
    overflow: auto;
}

.buckets {
    margin-top: 1rem;
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-around;
}

.buckets .title {
    display: flex;
    justify-content: center;
    
}

.buckets .bucket_container {
    flex: 1;
}

.buckets .bucket {
    background-color: #f0f0f0;
    height: 400px;
    border-radius: 1.25rem;
    min-height: 6rem;
    margin: .5rem;
    overflow: hidden;
    overflow-y: scroll;
    padding: .5rem;
}

.buckets .bucket_selected {
    border: 1px solid #00f;
}
.bucket::-webkit-scrollbar{
    width: 10px;
}

.bucket::-webkit-scrollbar-thumb{
    background: #A9A9A9;
}
.bucket::-webkit-scrollbar-button{
    color: #A9A9A9;
}

.boxes .box_container_desktop {
    flex: 1;
    display: flex;
    flex-direction: column;

    margin: 0 .5rem 0 0;
    position: relative;
}

.boxes .box_desktop {
    flex: 1;
    display: flex;
    flex-direction: column;

    border: 1px solid #e1e1e1;
    border-radius: 1.25rem;
    /* width: 940px; */
    /* margin: 0 .5rem 0 0;
    position: relative; */
}

.boxes .highlighted {
    box-shadow: inset 0 0 4px #00f;
}

.bottomContainer {
    margin: 1rem .5rem 1rem .5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.user-plus {

    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -0.4px;
    text-align: center;
    padding-left: 3px;
    padding-bottom: 3px;
    }

.user-plus::after {
    content: '\f234';
    font-family: font-awesome-solid;
    color: #ffffff;
}

.user-plus-container {
    width: 32px;
    height: 32px;
    background-color: #f42862;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
    border-radius: 50%;
}